<template>

<app-content :loading="is.loading && !is.initialised">

	<app-geolocation v-if="is.geolocating" :host="item.session.is.hosting" :position="item.geolocation" v-on:close="is.geolocating = false" v-on:confirm="onGeolocationConfirm" v-on:cancel="onGeolocationCancel" />

	<com-actions :item="item" v-if="is.initialised" v-on:geolocation="onGeolocationClick" />

	<com-alert-proposed v-if="isProposal && !window.is.mobile" :item="item" />
	<com-alert-ticketed v-if="isTicketed && !window.is.mobile" :item="item.ticket" />

	<app-content-body :is-grid="4" :loading="is.loading && is.initialised" v-if="is.initialised">

		<com-alert-proposed v-if="isProposal && window.is.mobile" :item="item" />
		<com-alert-ticketed v-if="isTicketed && window.is.mobile" :item="item.ticket" />

		<app-widget-summary :cancelled="item.cancelled" :headless="true" :span="3" :text="item.description" :image="item.image" :metrics="metrics" />

		<app-widget-info :cancelled="item.cancelled" :items="info" />

		<com-seats title="Seats" :seats="item.seats" v-if="item.seats.total > 0" />

		<app-widget-chat :span="2" :id="item.id" />

		<app-widget-activity :span="2" :id="item.id" />

	</app-content-body>

</app-content>

</template>

<script>

import mixItem from '@/mixin/item'

export default {

	mixins: [mixItem],

	components: {
		'com-seats': () => import('./conventionscheduleevent/Seats'),
		'com-actions': () => import('./conventionscheduleevent/Actions'),
		'com-alert-proposed': () => import('./conventionscheduleevent/AlertProposed'),
		'com-alert-ticketed': () => import('./conventionscheduleevent/AlertTicketed')
	},

	data: function() {

		return {
			autoload: false,
			is: {
				geolocating: false,
				geosaving: false
			},
			endpoint: 'convention/schedule/event',
			live: 'convention/schedule/event'
		}

	},

	created: function() {

		if (this.$route.query.payment_intent) {

			this.checkPayment()

		} else {

			this.load()

		}

	},

	computed: {

		isTicketed: function() {

			return this.item.ticket

		},

		isProposal: function() {

			return this.item.status === this.$constants.schedule.event.status.proposal

		},

		metrics: function() {

			return [
				{
					type: 'info',
					disabled: !this.item.type.games.enabled,
					avatar: this.item.host.avatar,
					forceAvatar: true,
					text: 'Host',
					value: this.item.host.name
				},
				{
					type: 'info',
					text: 'Type',
					value: this.item.type.name
				},
				{
					type: 'info',
					text: 'Experience',
					disabled: !this.item.type.games.enabled,
					tooltip: this.$constants.experience.tooltip[this.item.experience],
					value: this.$constants.schedule.experienceShort[this.item.experience] + ((this.item.teacher) ? ' (Teacher wanted)' : '')
				},
				{
					type: 'info',
					text: 'Age',
					value: this.$constants.schedule.ageShort[this.item.age]
				}
			]

		},

		info: function() {

			return [
				{
					icon: 'day',
					label: 'On',
					text: this.$filter('formatDate', this.item.date, 'dddd')
				},
				{
					icon: 'time',
					label: 'At',
					text: this.$filter('formatDate', this.item.date, this.$hourFormat)
				},
				{
					icon: 'duration',
					label: 'For',
					text: this.$filter('hours', this.item.duration) + ' hour' + ((this.item.duration === 3600) ? '' : 's')
				},
				{
					icon: 'location',
					label: 'In',
					text: this.item.location
				},
			]

		}

	},

	methods: {

		afterLoad: function() {

			if (this.$route.query.payment_intent) {

				this.$notify({
					message: 'Ticket successfully purchased'
				})

				this.$router.replace({'query': null})

			}

		},

		checkPayment: function() {

			this.$api.get('intent', {
				secret: this.$route.query.payment_intent_client_secret
			}).then(function(json) {

				if (json.sale && json.status !== this.$constants.registration.payment.processing) {

					this.load()

				} else {

					setTimeout(function() { this.checkPayment() }.bind(this), 2000)

				}

			}.bind(this), function() {

				setTimeout(function() { this.checkPayment() }.bind(this), 2000)

			})

		},
		
		onGeolocationConfirm: function(position) {

			this.is.geosaving = true
			this.is.geolocating = false

			this.$api.patch(['convention/schedule/event', this.item.id, 'geolocation'], {
				position: position
			}).then(function() {

				this.is.geosaving = false

				this.$notify({
					message: ['You have successfully set the geolocation for this event.']
				})

			}.bind(this))

		},
		
		onGeolocationCancel: function() {

			this.is.geosaving = true
			this.is.geolocating = false

			this.$api.patch(['convention/schedule/event', this.item.id, 'geolocation'], {
				position: false
			}).then(function() {

				this.is.geosaving = false

				this.$notify({
					message: ['You have successfully removed the geolocation for this event.']
				})

			}.bind(this))

		},

		onGeolocationClick: function() {

			this.is.geolocating = true

		}

	}

}

</script>

<style scoped>

</style>